// This page has defined `getInitialProps` to do data fetching.
// Next.js will execute `getInitialProps`
// It will wait for the result of `getInitialProps`
// When the results comes back Next.js will render the page.
// Next.js wil do this for every request that comes in.
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/auth-context';
import { navigate } from '../utils/functions'
import Loader from 'react-loader'
function HomePage() {
  const [ready, setReady] = useState(false)
  const { GLOBAL_OBJ } = useContext(AuthContext);
  useEffect(() => {
    console.log(GLOBAL_OBJ)
    if (!GLOBAL_OBJ.isLoggedIn && !GLOBAL_OBJ.token) {
      process.browser && navigate("login")
    }
    else {
      navigate("issue-ticket");
    }
  }, [GLOBAL_OBJ])
  return <div>Loading</div>
}

export default HomePage